'use client';

import HDialog from '../ui/Dialog';
import Button from '../ui/Button';
import { AndroidService } from '@/service/Android';
import { useState } from 'react';
import { StorageService } from '@/service/StorageService';

export default function BrowserSupportDialog() {
  const [openDialog, setOpenDialog] = useState(() => {
    if (StorageService.get('oldBrowser')) return false;
    return !isSupportedBrowser();
  });
  return (
    <HDialog
      isOpen={openDialog}
      onOpenChange={open => {
        setOpenDialog(false);
      }}
      position="bottom"
      noOutsideClickClose
      closable
    >
      <div className="flex flex-col items-center">
        <h1 className="text-xl font-semibold mt-4 text-black">
          Please update your browser to get the best experience
        </h1>
        <div className="flex justify-between items-center my-3 gap-4">
          <Button
            size="2"
            variant="soft"
            onClick={() => {
              setOpenDialog(false);
              StorageService.set('oldBrowser', 'true');
            }}
            className="border p-2 rounded"
          >
            Continue anyway
          </Button>

          <Button
            size="2"
            variant="solid"
            className="mr-3 border p-2 rounded"
            onClick={() => {
              AndroidService.openUrlInBrowser(
                'https://play.google.com/store/apps/details?id=com.google.android.webview'
              );
            }}
          >
            Update Browser
          </Button>
        </div>
      </div>
    </HDialog>
  );
}

function isSupportedBrowser(versions) {
  if (typeof window === 'undefined') return true;
  var userAgent = window.navigator.userAgent;

  if (!versions) {
    versions = {
      chrome: 88,
      edge: 88,
      firefox: 78,
    };
  }

  var {
    chrome: chromeVersion,
    edge: edgeVersion,
    firefox: firefoxVersion,
  } = versions;

  var isMobileChrome =
    /Chrome/.test(userAgent) &&
    parseInt(userAgent.match(/Chrome\/(\d+)/)[1], 10) > chromeVersion;
  var isFirefox =
    /Firefox/.test(userAgent) &&
    parseInt(userAgent.match(/Firefox\/(\d+)/)[1], 10) > firefoxVersion;
  var isEdge =
    /Edg/.test(userAgent) &&
    /Edge/.test(userAgent) &&
    parseInt(userAgent.match(/Edg\/(\d+)/)[1], 10) > edgeVersion;

  return isMobileChrome || isFirefox || isEdge;
}
